<template>
  <div v-if="permission.course_completion_access">
    <div class="w-100 text-right">
      <b-row class="justify-content-between mb-2">
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            @on-change="paginateChange"
            :options="perPageOptions"
            v-model="search.paginate"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="4">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="search.search_data"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col md="2">
                <Select
                  v-model="search.is_complete"
                  filterable
                  @on-change="SearchData"
                  :clearable="true"
                  name="is_complete"
                  id="is_complete"
                  placeholder="Is Complete"
                >
                  <Option :value="1">Complete</Option>
                  <Option :value="2">Not Complete</Option>
                </Select>
              </b-col>
              <b-col md="2">
                <Select
                  v-model="search.course_id"
                  filterable
                  @on-change="SearchData"
                  :clearable="true"
                  name="course_id"
                  id="course_id"
                  placeholder="Select Purchase Coruse"
                >
                  <Option
                    :value="course.id"
                    v-for="(course, index) in courses"
                    :key="index"
                    v-if="courses.length"
                    >{{ course.name }}</Option
                  >
                </Select>
              </b-col>
              <b-col md="2">
                <Select
                  v-model="search.teacher_id"
                  filterable
                  @on-change="SearchData"
                  :clearable="true"
                  name="teacher_id"
                  id="teacher_id"
                  placeholder="Select Teacher"
                >
                  <Option
                    :value="teacher.id"
                    v-for="(teacher, index) in teachers"
                    :key="index"
                    v-if="teachers.length"
                    >{{ teacher.name }}</Option
                  >
                </Select>
              </b-col>

              <b-col md="2">
                <DatePicker
                  format="yyyy-MM-dd"
                  type="date"
                  name="purchase_from_date"
                  v-model="search.from_date"
                  id="purchase_from_date"
                  placeholder="Purchase From Date"
                  :clearable="true"
                  style="width: 100%"
                ></DatePicker>
              </b-col>
              <b-col md="2">
                <DatePicker
                  format="yyyy-MM-dd"
                  type="date"
                  name="purchase_to_date"
                  v-model="search.to_date"
                  id="purchase_to_date"
                  placeholder="Purchase To Date"
                  :clearable="true"
                  style="width: 100%"
                ></DatePicker>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="align-middle text-center">Sl</th>
                  <th>Student Info</th>
                  <th width="260">Course Info</th>
                  <th>Timestamp</th>
                  <th class="text-center">Status</th>
                  <th>Completion Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(purchase, index) in purchases.data"
                  :key="index"
                  v-if="purchases.data.length"
                >
                  <td class="align-middle text-center">
                    <p>{{ purchases.from + index }}</p>
                  </td>
                  <td class="align-middle">
                    <div v-if="purchase.student">
                      <p class="mb-0">
                        <b>Name :</b> {{ purchase.student.name }}
                      </p>
                      <p class="mb-0" v-if="purchase.student.number">
                        <b>Number : </b>{{ purchase.student.number }}
                      </p>
                      <p class="mb-0" v-if="purchase.student.email">
                        <b>Email : </b>{{ purchase.student.email }}
                      </p>
                    </div>
                  </td>
                  <td class="align-middle">
                    <p class="mb-0">
                      <b>Name : </b> {{ purchase.course.name }}
                    </p>
                  </td>
                  <td class="align-middle">
                    <p
                      class="mb-0"
                      v-if="
                        purchase.course_status &&
                        purchase.course_status.start_time
                      "
                    >
                      <b>Start Time : </b
                      >{{
                        purchase.course_status.start_time | date_time_format
                      }}
                    </p>
                    <p
                      class="mb-0"
                      v-if="
                        purchase.course_status &&
                        purchase.course_status.end_time
                      "
                    >
                      <b>End Time : </b
                      >{{ purchase.course_status.end_time | date_time_format }}
                    </p>
                    <p
                      v-if="
                        purchase.course_status &&
                        purchase.course_status.start_time == null
                      "
                    >
                      <span class="badge badge-info">Not Start</span>
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      style="margin: 3px"
                      :class="
                        purchase.course_status.status == 1
                          ? 'badge-success'
                          : 'badge-warning'
                      "
                      >{{
                        purchase.course_status.status == 1
                          ? "Complete"
                          : "Incomplete"
                      }}</span
                    >
                  </td>
                  <td class="align-middle">
                    <p class="mb-2">
                      {{
                        completionPercentage(
                          purchase.total_complete_lesson,
                          purchase.total_lesson
                        )
                      }}
                      %
                      <b-progress
                        :value="
                          completionPercentage(
                            purchase.total_complete_lesson,
                            purchase.total_lesson
                          )
                        "
                        max="100"
                        class="progress-bar-warning"
                        variant="warning"
                      />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ purchases.from ? purchases.from : 0 }} to
                  {{ purchases.to ? purchases.to : 0 }} of
                  {{ purchases.total ? purchases.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="purchases"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BProgress,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      search: {
        is_complete: null,
        course_id: null,
        teacher_id: null,
        from_date: "",
        to_date: "",
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      purchases: {},
      image: this.$store.state.base_url + "images/student/default.svg",
    };
  },
  mounted() {
    this.$store.dispatch("getActiveCourses");
    this.$store.dispatch("getTeacher");
    this.getResults();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const purchases = await this.callApi(
        "post",
        "/app/report/student/course/completion?page=" + this.search.page,
        this.search
      );
      if (purchases.status == 200) {
        this.purchases = purchases.data.purchaseCourses;
      }
    },

    completionPercentage(total_complete_lesson = 0, total_lesson = 0) {
      return total_lesson > 0
        ? Math.round((total_complete_lesson / total_lesson) * 100)
        : 0;
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  watch: {
    "search.from_date": function (val) {
      this.SearchData();
    },
    "search.to_date": function (val) {
      this.SearchData();
    },
    "search.purchase_from_date": function (val) {
      this.SearchData();
    },
    "search.purchase_to_date": function (val) {
      this.SearchData();
    },
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BProgress,
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.report;
    },
    courses() {
      return this.$store.getters.getActiveCourses;
    },
    teachers() {
      return this.$store.getters.getTeacher;
    },
  },
};
</script>

<style></style>
